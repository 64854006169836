#contact {
    max-width: 100%;
}

.form-visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-invisible {
    display: none;
}