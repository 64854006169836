* {
  font-family: 'Playfair Display';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navigation {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
}