#home {
    min-height: calc(100vh - 80px);
    background: url("../images/backgroundCropped.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    font-family: Impact; 
    max-width: 100%;
}

.mainVerticalContainer {
    max-width: 100%;
    padding: 25px;
    margin: 0 25px;
    height: fit-content;
    background: white;
}

@media screen and (max-width: 1000px) {
    #home {
        padding-top: 50px;
    }
}

@media screen and (min-width: 1000px) {
    #home {
        background: url("../images/background.jpeg");
        background-repeat: no-repeat;
        background-size: fill;
        background-position: right;
        overflow: hidden;
    }

    .mainVerticalContainer {
        max-width: 100%;
        padding: 5% 75px;
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
    }
}